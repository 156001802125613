

import React from 'react';
import { AiResultsContract_ } from "../components/AiResultsContract/AiResultsContract";
import { useParams } from 'react-router-dom';

function AiResults() {
  const { ai_request_id } = useParams();

  return (
    <AiResultsContract_ ai_request_id={ai_request_id} />
  );
}
export default AiResults;
