import * as ReactDOM from "react-dom";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import DashboardPage from './pages/Dashboard.js';
import CreateOptions from "./pages/CreateOptions";
import CreateContract from "./pages/CreateContract.js";
import CreateSuccess from "./pages/CreateSuccess.js";
import Contract from "./pages/Contract.js";
import Integrate from "./pages/Integrate.js"
import AiCreate from "./pages/AiPrompt.js"
import AiResults from "./pages/AiResults.js";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <AiCreate/>,
    loader: async () => {
      return 1;
    },
  },
  {
    path: "/eos-evm",
    element: <AiCreate/>,
    loader: async () => {
      return 1;
    },
  },
  {
    path: "create",
    element: <CreateOptions/>,
  },
  // {
  //   path: "create/:contractId",
  //   element: <CreateContract/>,
  // },
  {
    path: "create/success",
    element: <CreateSuccess/>,
  },
  // {
  //   path: "contract/:contractAddress",
  //   element: <Contract/>,
  // },
  {
    path: "create/ai/:ai_request_id",
    element: <AiCreate/>,
  },
  {
    path: "create/ai/request/:ai_request_id",
    element: <AiCreate/>,
  },
  {
    path: "create/ai/results/:ai_request_id",
    element: <AiResults/>,
  },
  {
    path: "integrate/:contractId/:contractAddress",
    element: <Integrate/> 
  }
]);
