import { useEffect, useState } from 'react'
import { useNetwork, useSwitchNetwork } from 'wagmi'
import { ModalComponent } from '../Modal/Modal'
import { Button, Box } from "@chakra-ui/react";

export const NetworkSwitch = () => {
    const { chain } = useNetwork()
    const { chains, error, isLoading, pendingChainId, switchNetwork } =
    useSwitchNetwork()

    const [open, setOpen] = useState(false)

    // useEffect(() => {
    //     if (chain) {
    //         if (chain && chain.id != 15557) {
    //             changeNetworks()
    //         } else if (chain.id == 15557) {
    //             setOpen(false)
    //         }
    //     } else {
    //         console.error("Wallet issue, check wallet connection.")
    //     }
    // }, [chain?.id])

    // const changeNetworks = async () => {
    //     try {
    //         setOpen(true)
    //         if (chain && chain.id != 15557) {
    //             await switchNetwork?.(15557)
    //         } else {
    //             setOpen(false)
    //         }
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    return(
        <ModalComponent open={open}>
           {/* <Box p={4} borderWidth="1px" borderRadius="md">
                <p>To use the Smartpress app, you'll need to connect your Ethereum wallet and switch to the required network.</p>
                <Button onClick={changeNetworks} isLoading={isLoading} loadingText="Connecting..." mt={4}>
                    Connect Ethereum Wallet and Switch Networks
                </Button>
            </Box> */}
        </ModalComponent>
    )
}