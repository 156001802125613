import { useState } from "react";
import {
  Box,
  Stack,
  Text,
  Button,
  CircularProgress,
  Center,
} from "@chakra-ui/react";
import {
  shorten
} from "../../libs/string"

export function Stepper(props) {
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);

  const stepsShort = props.stepsShort;
  const stepsLong = props.stepsLong;

  return (
    <Stack direction="row" spacing="2rem">
      <Box h="240px" w="8rem">
        <Text fontWeight="bold" textAlign="center" mb="1rem">
          Progress
        </Text>
        <Stack spacing="0.5rem">
          {[0, 1, 2, 3].map((index) => (
            <Box
              key={index}
              bg={index === props.step ? "blue.500" : "gray.200"}
              w="100px"
              borderRadius="md"
              pb={0}
              pl={2}
            >
                {index + 1}. {stepsShort[index]} 
            </Box>
          ))}
        </Stack>
      </Box>
      <Box>
        <Box>
          <Box
            bg="gray.200"
            w="16rem"
            h="10rem"
            borderRadius="md"
            boxShadow="md"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {loading ? (
              <CircularProgress isIndeterminate color="blue.500" size="2rem" />
            ) : (
              <>
                <Center>
                  <Text w="80%">{stepsLong[props.step]} {shorten(props.message)}</Text>
                </Center>
                <br></br>
                <br></br>
                {}
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Stack>
  );
}