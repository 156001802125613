import React, { useState, useEffect } from 'react';
import { AiCreateContract_ } from '../AiCreateContract/AiCreateContract_';
import { DeployContractBytes } from '../DeployContractBytes.js/DeployContractBytes';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useNavigate } from 'react-router-dom';
import Discord from '../Icons/Discord';
import {
    Text,
    Heading,
    Box,
    Button
  } from "@chakra-ui/react";
import ContentReveal from '../ContentReveal/ContentReveal'
import { ShareButton } from "../Share/Share"
import { useMediaQuery } from '@chakra-ui/react';
import CopyButton, { copyToClipboard } from '../CopyToClipboard/CopyToClipboard'
import Dots from '../Animations/Dots';

export const PollingForCode = (props) => {
  const [data, setData] = useState(null);
  const [flattenedCode, setFlattenedCode] = useState(null);
  const [status, setStatus] = useState(null);
  const [prompt, setPrompt] = useState(null);
  const [codeError, setCodeError] = useState(null);
  const [r, setR] = useState(null);

  const [isLargerThanMobile] = useMediaQuery('(min-width: 480px)');

  const navigate = useNavigate({options: {replace: false}});

  useEffect(() => {

    console.log(process.env.REACT_APP_API_URL)

    let requestId;
    // Existing code or new request
    if (props.existingRequest) {
        console.log(props)
        requestId = props.requestId
    } else {
        requestId = props.contractId
        navigate(`/create/ai/request/${props.contractId}`);
    }

    let codeIntervalId;
    let statusIntervalId;
    let promptIntervalId;
    let flattenedCodeIntervalId;
    let codeErrorIntervalId;

    const fetchCode = async () => {
        console.log(props)
        setR(r + 1)
        const response = await fetch('https://' + process.env.REACT_APP_API_URL + '/getContract', {
            method: 'POST',
            body: JSON.stringify({id: requestId}),
            timeout: 30
          })
          
        const data = await response.json();

        setData(data);
        if (Object.keys(data).length > 0) {
            navigate(`/create/ai/results/${requestId}`);
            console.table(JSON.parse(data.message.contents.S))
            clearInterval(codeIntervalId);
            clearInterval(statusIntervalId);
        }  
    };

    const fetchStatus = async () => {
        console.log(props)
        setR(r + 1)
        const response = await fetch('https://' + process.env.REACT_APP_API_URL + '/getStatus', {
            method: 'POST',
            body: JSON.stringify({id: requestId}),
            timeout: 30
          })
          
        const data = await response.json();

        setStatus(data);
        if (Object.keys(data).length > 0) {
            console.table(JSON.parse(data.message.contents.S))
        }  
    };


    const fetchFlattened = async () => {
        console.log(props)
        setR(r + 1)
        const response = await fetch('https://' + process.env.REACT_APP_API_URL + '/flattenContract', {
            method: 'POST',
            body: JSON.stringify({id: requestId}),
            timeout: 30
          })
          
        const data = await response.json();

        setFlattenedCode(data);
        // if (Object.keys(data).length > 0) {
        //     console.table(JSON.parse(data.message.contents.S))
            clearInterval(flattenedCodeIntervalId)
        // }  
    };

    const fetchPrompt = async () => {
        console.log(props)
        setR(r + 1)
        const response = await fetch('https://' + process.env.REACT_APP_API_URL + '/getPrompt', {
            method: 'POST',
            body: JSON.stringify({id: requestId}),
            timeout: 30
          })
          
        const data = await response.json();

        setPrompt(data);
        if (Object.keys(data).length > 0) {
            console.table(JSON.parse(data.message.contents.S))
            clearInterval(promptIntervalId)
        }  
    };

    const fetchCodeError = async () => {
        console.log(props)
        setR(r + 1)
        const response = await fetch('https://' + process.env.REACT_APP_API_URL + '/getContractError', {
            method: 'POST',
            body: JSON.stringify({id: requestId}),
            timeout: 30
          })
          
        const data = await response.json();

        setCodeError(data);
        if (Object.keys(data).length > 0) {
            console.table(JSON.parse(data.message.contents.S))
            clearInterval(codeErrorIntervalId)
        }  
    };

    fetchCode();
    fetchPrompt();
    fetchFlattened();
    fetchCodeError();
    codeIntervalId = setInterval(fetchCode, 10000); // Polling interval of 5 seconds
    flattenedCodeIntervalId = setInterval(fetchFlattened, 12000); // Polling interval of 5 seconds
    statusIntervalId = setInterval(fetchStatus, 8000); // Polling interval of 8 seconds
    promptIntervalId = setInterval(fetchPrompt, 8000); // Polling interval of 8 seconds
    codeErrorIntervalId = setInterval(fetchCodeError, 10000); // Polling interval of 20 seconds

    return () => {
      clearInterval(codeIntervalId); 
      clearInterval(flattenedCodeIntervalId); 
      clearInterval(statusIntervalId);
      clearInterval(promptIntervalId);
      clearInterval(codeErrorIntervalId)
    };
  }, []);

  const shareText = `Check out this Ethereum app  i made on EthCreator AI - ${window.location.href}`

  return (
    <div>

        { !data?.message?.contents ?  
            <span>
                <Heading mb="5" mt="0">Generating Application</Heading>
                <Text fontSize="xl" mb={4}>Estimated delivery: 1-3 mins</Text>
                <SyntaxHighlighter language="solidity" style={materialDark}>
                    {`Request ID: ${props.requestId} ${status ? ("\n\n"+ status?.message ?  "\n\n"+ JSON.stringify(status?.message?.contents?.S): "") : ""}`}
                </SyntaxHighlighter>
            </span> : <>
                <Heading mb="2">Your Ethereum application Is Built!</Heading> 
                <div style={isLargerThanMobile ? {float:"right", marginTop:"-50px"} : {margin:"16px 0"}}>
                    <ShareButton content={shareText}></ShareButton>
                </div>

                <br></br>

                <Heading size="md" mb="2">Next steps</Heading>

                <a target="_blank" href="https://forms.gle/Z9rvoPhz6hgY4b5r6">
                    <Button 
                        mt="2"
                        mr="4"
                        mb="4"
                        colorScheme="blue"
                    >
                        Get expert help for your project
                    </Button>
                </a>          
                    
                {/* <Button leftIcon={Discord} color={"#7289DA"}><Discord/>Join the Discord</Button> */}
            </>
        }

        

        { flattenedCode?.message?.flattenedCode && data?.message?.contents 
            ?  
            <Heading size="md" mb="4" mt="4">If you're ready to deploy</Heading>
                : 
            ""
        }

        {/* Change to bytecode from flat build */}
        { flattenedCode?.message?.flattenedCode && data?.message?.contents 
            ?  
            <DeployContractBytes bytecode={flattenedCode?.message?.buildOutput[JSON.parse(data?.message?.contents?.S)?.contractName]} abi={JSON.parse(data.message.contents.S).contractBytes.abi} requestId={props.requestId}></DeployContractBytes>
                : 
            data?.message?.contents ? <span><Heading size="sm" mt="3" mb="4">Preparing deployer</Heading><Dots></Dots></span> : ""
        }

{/* 
        { data?.message?.contents ? <SyntaxHighlighter language="solidity" style={materialDark}>
            {prompt}
        </SyntaxHighlighter> : ""} */}

        { prompt?.message?.contents ? <Heading mt="24px" size="sm">Prompt</Heading> : ""}

        { prompt?.message?.contents ? <SyntaxHighlighter language="solidity" style={materialDark}>{`Prompt: ${prompt?.message?.contents?.S}`}</SyntaxHighlighter> : ""}

        { data?.message?.contents ? <Heading mt="8" size="sm">Code output</Heading> : ""}

        { data?.message?.contents ? 
            <ContentReveal 
                code={
                    <SyntaxHighlighter language="solidity" style={materialDark}>
                        {JSON.parse(data?.message?.contents?.S)?.finalCode?.sources ? JSON.parse(data?.message?.contents?.S)?.finalCode?.sources["Contract.sol"]?.content : JSON.parse(data?.message?.contents?.S)?.finalCode}
                    </SyntaxHighlighter>}
                rawCode={JSON.parse(data?.message?.contents?.S)?.finalCode?.sources ? JSON.parse(data?.message?.contents?.S)?.finalCode?.sources["Contract.sol"]?.content : JSON.parse(data?.message?.contents?.S)?.finalCode}> 
            </ContentReveal>
        : ""}

        { flattenedCode?.message?.flattenedCode && data?.message?.contents ? <Heading mt="0" size="sm">Flattened output</Heading> : ""}

        { flattenedCode?.message?.flattenedCode && data?.message?.contents ? 
            <ContentReveal 
                code={<SyntaxHighlighter language="solidity" style={materialDark}>
                        { flattenedCode?.message?.flattenedCode }
                    </SyntaxHighlighter>}
                rawCode={flattenedCode?.message?.flattenedCode}
            > 
            </ContentReveal>
        : ""}

        { codeError?.message?.contents?.S && !flattenedCode?.message?.flattenedCode ? <Heading mt="8" size="sm">AI output (Unable to deploy)</Heading> : ""}

        { codeError?.message?.contents?.S && !flattenedCode?.message?.flattenedCode ? 
            <ContentReveal 
                code={
                    <SyntaxHighlighter language="solidity" style={materialDark}>
                        {codeError?.message?.contents?.S}
                    </SyntaxHighlighter>}
                rawCode={codeError?.message?.contents?.S}> 
            </ContentReveal>
        : ""}

        { data?.message?.contents ? <Heading size="sm">Build data</Heading> : ""}
        { data?.message?.contents ? 
            <Box mb="50px"><SyntaxHighlighter language="solidity" style={materialDark}>
                {JSON.stringify(data?.message?.contents?.S)}
            </SyntaxHighlighter></Box>
        : ""}

    </div>
  );
};