import { Box, Flex, Heading, Subheading, SimpleGrid, useBreakpointValue } from "@chakra-ui/react";
import { Card } from "../components/Card/Card"
import { ActionButton } from "../components/ActionButton/ActionButton"
import { LinkTabs } from "../components/LinkTabs/LinkTabs"
import { AppContainer } from "../components/AppContainer/AppContainer"
import TermsAndConditions from "../components/T&C/Terms&Conditions";

const CardContainer = ({ children }) => {
  const columnCount = useBreakpointValue({ base: 1, md: 3 });
  
  return (
    <Box bg="white" borderRadius="lg" shadow="lg" overflow="hidden" mb={{ base: 4, md: 0 }}>
      {children}
    </Box>
  );
};

const CardGrid = () => {
    return (
        <SimpleGrid columns={[1, null,  ]} spacing="6">
            <CardContainer>
                <Card
                    title="Create with AI"
                    description="List the features you desire and EthCreator will write the code, compile it and help you deploy it to your desired chain."
                    type=""
                    to="../create/ai/start"
                />
            </CardContainer>
            {/* <CardContainer>
                <Card
                    title="Token contract"
                    description="The most basic type of token. All units are fungible meaning they are the same as eachother, ERC20 tokens are generally used as units of account in games and platforms."
                    type="ERC20"
                    to="erc20"
                />
            </CardContainer> */}
            {/* <CardContainer>
                <Card
                    title="NFT contract"
                    description="Token that is used for unique works of art or collectables. All units are unique and treated separately. Each unit can have its own characteristics."
                    type="ERC721"
                    to="erc721"
                />
            </CardContainer> */}
            {/* <TermsAndConditions></TermsAndConditions> */}
        </SimpleGrid>
    );
}

const ContractOptions = () => {
  return (
    <span>
      <CardGrid></CardGrid>
    </span>
  );
};

export default ContractOptions;