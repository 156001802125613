import { configureChains, createClient, setChainId } from 'wagmi'
import { w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { EthereumClient } from '@web3modal/ethereum'
import { CHAINS } from '../config/chains'

const chains = Object.values(CHAINS)
export const projectId = '6351c626f45830de794281d7358030fd'

const { provider } = configureChains(chains, [w3mProvider({ projectId })])

export const wagmiClient = createClient({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, version: 2, chains }),
  provider
})

export const ethereumClient = new EthereumClient(wagmiClient, chains)