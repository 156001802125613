export function shorten(str) {
    if (typeof str == 'string') {
        if (str.length <= 10) {
            return str;
          } else {
            const firstFiveChars = str.slice(0, 5);
            const lastFiveChars = str.slice(str.length - 5, str.length);
            return `${firstFiveChars}....${lastFiveChars}`;
          }
    } else {
        return ""
    } 
}

export function urlEncode(obj) {
    let params = [];
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        if (typeof value === 'string' && value.length <= 70) {
          const encodedValue = encodeURIComponent(value);
          params.push(`${key}=${encodedValue}`);
        }
      }
    }
    return params.join('&');
  }

  export function removeQuotesFromString(str) {
    return str.replace(/'/g, ''); // Remove all double quotes from the string
  }

  export const isNumber = (str) => {
    return !isNaN(parseFloat(str)) && isFinite(str);
  };