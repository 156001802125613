import React from 'react';
import { Box, Heading, Text, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon } from '@chakra-ui/react';

const DisplayJson = ({ data }) => {
  const renderKeyValue = (key, value) => {
    if (typeof value === 'object' && value !== null) {
      return (
        <Accordion allowMultiple>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  {key}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <DisplayJson data={value} />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      );
    } else {
      return (
        <Box>
          <Text as="span" fontWeight="bold">
            {key}:
          </Text>{' '}
          {value}
        </Box>
      );
    }
  };

  return (
    <Box>
      {Object.keys(data).map((key) => (
        <Box key={key} mb={2}>
          {renderKeyValue(key, data[key])}
        </Box>
      ))}
    </Box>
  );
};

export default DisplayJson;
