import { useState, useEffect } from "react";
import {
  Box
} from "@chakra-ui/react";
import { useSigner } from 'wagmi';
import { NetworkSwitch } from "../NetworkSwitch/NetworkSwitch";
import AlertBanner from "../Alert/Alert";
import CodeBot from "../ChatBot/CodeBot"

export function AiCreateContract_(props) {

  const { data: signer, isError, isLoading } = useSigner({
    onError(error) {
      console.log('Error', error)
    },
  })

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box mb="5" width="100%" bg="white" borderRadius="lg" shadow="lg" p="4">
        {/* {!signer ? <AlertBanner status="info" title="Notice" message="Connect your wallet before deploying" ></AlertBanner> : ""} */}
        <CodeBot></CodeBot>
      </Box>

      <NetworkSwitch></NetworkSwitch>
    </Box>
  );
}