import React from 'react';
import './Dots.css'; // Make sure to create this CSS file

const Dots = () => {
  return (
    <div className="three-dots-container">
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
    </div>
  );
};

export default Dots;