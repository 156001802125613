

import React from 'react';
import { AiCreateContract_ } from "../components/AiCreateContract/AiCreateContract_";
import DashboardInfo from '../components/DashboardInfo/DashboardInfo';
import EOS_EVM from '../components/DashboardInfo/chains/EOS_EVM'
import PremadeContracts from '../components/PremadeContracts/PremadeContracts'
import { useLocation } from 'react-router-dom';
import Tweets from '../components/Tweet/Tweets';

function AiCreate() {

  const location = useLocation();

  console.log(location)

  return (<>
    {location.pathname === "/" ? <DashboardInfo/> : <></>}
    {location.pathname === "/eos-evm" ? <EOS_EVM/> : <></>}
    
    <div style={{marginBottom: "80px"}}>
      <AiCreateContract_/>
    </div>

    {/* <Tweets></Tweets> */}
    
    {/* <PremadeContracts></PremadeContracts> */}
    </>
  );
}
export default AiCreate;
