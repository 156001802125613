import { useEffect, useState } from "react";
import {
  Box,
} from "@chakra-ui/react";
import { useSigner } from 'wagmi';
import { NetworkSwitch } from "../NetworkSwitch/NetworkSwitch";
import AlertBanner from "../Alert/Alert";
import { PollingForCode } from "../PollingForCode/PollingForCode";
import { useMediaQuery } from '@chakra-ui/react';


import { Web3Button, Web3Modal, Web3NetworkSwitch } from "@web3modal/react"


export function AiResultsContract_ (props) {


  const ConnectButton = () => {
    return <Web3Button style={{marginLeft:"5px", transform:"scale(0.8)"}} />
  }

  const { data: signer, isError, isLoading } = useSigner({
    onError(error) {
      console.log('Error', error)
    },
  })

  const [isLargerThanMobile] = useMediaQuery('(min-width: 480px)');

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      pb="20"
    >
      <Box width="100%" bg="white" borderRadius="lg" shadow="lg" p="4">
        {!signer ? <div style={{marginBottom:"12px"}}><AlertBanner status="info" title={isLargerThanMobile ? "Notice" : ""} message="Connect your wallet before deploying" >
          <ConnectButton></ConnectButton>
        </AlertBanner></div> : ""}
        {signer ? <div style={{marginBottom:"12px"}}><ConnectButton></ConnectButton></div> : ""}
        <PollingForCode existingRequest={true} requestId={props.ai_request_id}></PollingForCode>
      </Box>
    </Box>
  );
}