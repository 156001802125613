import { useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Text,
  Flex,
  Button,
  Heading
} from "@chakra-ui/react";
import TermsAndConditions from "../T&C/Terms&Conditions";
import { deployPrecompiledContract } from '../../libs/ethers';
import { useSigner } from 'wagmi';
import { ModalComponent } from "../Modal/Modal";
import { NetworkSwitch } from "../NetworkSwitch/NetworkSwitch";
import { Stepper } from "../CreateStepper/CreateStepper";
import { useNavigate } from 'react-router-dom';
import { urlEncode } from "../../libs/string"
import {ConstructorForm} from "../ConstructorForm/ConstructorForm";
import { FaucetList } from "../FaucetList/FaucetList";
import { useMediaQuery } from '@chakra-ui/react';


export function DeployContractBytes(props) {
  const [step, setStep] = useState(0);
  const [open, setOpen] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const handleTermsCheckChange = () => {
    setAccepted(!accepted);
  };
  
  const [isLargerThanMobile] = useMediaQuery('(min-width: 480px)');


  // Constructor args coming from constructor
  const [args, setArgs] = useState(false);

  const [message, setMessage] = useState(false);

  const navigate = useNavigate();

  const { data: signer, isError, isLoading } = useSigner({
    onError(error) {
      console.log('No signer')
      console.log('Error', error)
    },
  })
  
  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  function handleArgs(args) {
    setArgs(args)
  }

  function handleSubmit () {

    (async () => {
      setOpen(true)

      console.log(signer)
      console.log(urlEncode(signer.provider._network))

      try {
        console.log( args)
        console.log( props.bytecode)
        console.log("STEP 1")
        const generator = await deployPrecompiledContract(signer, args ? props.bytecode + args : props.bytecode)
        const step1 = await generator.next()
        await sleep(1000)
        console.log(step1);
        const address = await generator.next()
        await sleep(1000)
        console.log(address);
        setMessage(`${address.value}`)
        setStep(1)

        const txHash = await generator.next()
        console.log(txHash);
        setMessage(`${txHash.value}`)
        setStep(2)

        const contractAddressAndReceipt = await generator.next()
        console.log(contractAddressAndReceipt);
        setMessage(`${contractAddressAndReceipt.value.address}`)
        setStep(3)

        await sleep(2000)

        console.log(signer)

        navigate('/create/success?' + urlEncode(contractAddressAndReceipt.value.receipt) 
                    + `&network=${signer.provider._network.name}`
                    + `&requestId=${props.requestId}`);

      } catch (error) {
        console.log(error)
      }
    })()
  }

  const stepsShort = ['Compile','Sign','Deploy','Finish'];
  const stepsLong = ['Compiling contract', 'Sign deployment from account:', 'Deploying app from account:','Finished deployment'];

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      
      { props.bytecode ? <Box width="100%" bg="white" borderRadius="lg">
            <ConstructorForm handleArgs={handleArgs} abi={JSON.parse(props.abi)}></ConstructorForm>

            <br></br>

            {/* <div style={{"opacity": accepted ? "0.2" : "1", "backgroundColor": "rgba(0,0,0,0.1)", "padding":"4px 10px 10px 10px"}}>
            <Flex borderRadius={"10px"}  mt={isLargerThanMobile ? "6px" : "28px"} mb="-6px">
              <TermsAndConditions text="See Terms and Conditions" color="blue"></TermsAndConditions>
            </Flex>
            <Flex mt="10px" mb="-6px">
              
              <p> I have read and understand to the Terms And Conditions</p>

              <input
                  style={{marginRight:"5px", transform:"scale(1.4)", cursor:"pointer", margin: "0 0 0 10px"}}
                  type="checkbox"
                  checked={accepted}
                  onChange={handleTermsCheckChange}
              />
            </Flex>
          </div> */}

            <Button 
                mt="2"
                mr="4"
                isDisabled={!signer}
                onClick={() => {
                    setOpen(true)
                    handleSubmit()
                }}
                colorScheme="green"
            >Deploy to any blockchain</Button>

            <FaucetList></FaucetList>

            <ModalComponent open={open} title="Deploy contract">
                <Stepper step={step} message={message} stepsShort={stepsShort} stepsLong={stepsLong}></Stepper>
                <Button size="sm" onClick={() => setOpen(false)}>close</Button>
            </ModalComponent>
        </Box>
       : ""}

      <NetworkSwitch></NetworkSwitch>
    </Box>
  );
}