import { useSigner } from 'wagmi';
import { ERC721_bytecode, ERC721_abi } from '../contracts/contracts'

import {ethers} from "ethers"

export const abiCoder = new ethers.utils.AbiCoder()


export const deployPrecompiledContract = async function* (signer, bytecode) {
  console.log("0x" + bytecode)

  yield "Prepared"

  // Create the deployment transaction

  const deployTx = {
      // to: await signer.getAddress(),
      from: await signer.getAddress(),
      data: "0x" + bytecode,
      // gasEstimate: ethers.BigNumber.from(6000000),
  };
  console.log(deployTx)
  const gasEstimate = await signer.estimateGas(deployTx);
  // deployTx.gasLimit = gasEstimate * 1.5;
  console.log(gasEstimate)

  yield await signer.getAddress()

  // Sign and send the transaction
  const transaction = await signer.sendTransaction(deployTx);
  console.log('Transaction Hash:', transaction.hash);

  yield transaction.hash

  // Wait for the transaction to be mined
  const receipt = await transaction.wait();
  console.log('Deployed Contract Address:', receipt.contractAddress);

  return {
      receipt, 
      address: receipt.contractAddress
  }
}

    
export const deployContract = async function* (contractId, signer, params, code) {

    let args;
    let factory;

    console.log(code)

    if (code) {
        // Todo, no need to compile anymore.

        let compilationResponse = await compileContract(code);

        console.log(compilationResponse)
        console.log("0x" + compilationResponse.output.bytecode)
        factory = new ethers.ContractFactory(compilationResponse.output.abi, "0x" + compilationResponse.output.bytecode, signer);
    } else {
        abiCoder.encode(['string', 'string'], [params['name'], params['symbol']])
        new ethers.ContractFactory(ERC721_abi, ERC721_bytecode + args.slice(2), signer);
    }

    // const gasEstimate = await factory.estimateGas.deploy();

    yield "Prepared"

    // Create the deployment transaction
    const deployTx = {
        // to: await signer.getAddress(),
        from: await signer.getAddress(),
        data: factory.bytecode,
        // gasLimit: gasEstimate,
    };

    yield await signer.getAddress()

    // Sign and send the transaction
    const transaction = await signer.sendTransaction(deployTx);
    console.log('Transaction Hash:', transaction.hash);

    yield transaction.hash

    // Wait for the transaction to be mined
    const receipt = await transaction.wait();
    console.log('Deployed Contract Address:', receipt.contractAddress);

    return {
        receipt, 
        address: receipt.contractAddress
    }
}



const compileContract = async (code) => {

    try {
      const response = await fetch('https://qkb75icdxe.execute-api.us-east-1.amazonaws.com/', {
        method: 'POST',
        body: code
      })

      if (response.ok) {
        const data = await response.json();
        console.log(data)
        return data;
      } else {
        console.log('Request was not successful.');
      }
    } catch (error) {
      console.log('Error occurred: ' + error.message);
    }
  };

