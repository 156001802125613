import { useState } from "react";
import { useMediaQuery, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton } from "@chakra-ui/react";

export function ModalComponent({ open: isOpen, title, children, fullScreen }) {

  const handleOpen = () => {};
  const handleClose = () => {};

  const [isLargerThanMobile] = useMediaQuery('(min-width: 480px)');

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent maxW={fullScreen ? "100vw" : '450px'} maxHeight={fullScreen ? isLargerThanMobile ? "100vh" : "80vh" : ''} height={fullScreen ? isLargerThanMobile ? "100vh" : "80vh" : ''}>
          <ModalHeader>{title}</ModalHeader>
          {/* <ModalCloseButton /> */}
          <ModalBody style={{backgroundColor:"white"}} css={{ scrollBehavior: "auto" }}>{children}</ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
