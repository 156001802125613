import { Box, SimpleGrid, useBreakpointValue, Button, Link, useMediaQuery } from "@chakra-ui/react";
import { Card } from "../../Card/Card"
import { SignedIn, SignedOut, UserButton, useClerk, SignInButton, SignOutButton } from "@clerk/clerk-react";

const CardContainer = ({ children }) => {
  const columnCount = useBreakpointValue({ base: 1, md: 3 });
  
  return (
    <Box bg="white" borderRadius="lg" overflow="hidden" mb={{ base: 4, md: 0 }}>
      {children}
    </Box>
  );
};

const CardGrid = (info) => {

    return (
      <SignedOut>
        <SimpleGrid mb="5" columns={[1, null, 3]} spacing="6">
            <CardContainer>
            <Card
                title={info[0].title}
                description={info[0].description}
                pillText="Build"
                link={{url: "/create", text: "Start Buidling"}}
            />
            </CardContainer>
            <CardContainer>
            <Card
                title={info[1].title}
                description={info[1].description}
                pillText="Compile"
            />
            </CardContainer>
            <CardContainer>
            <Card
                title={info[2].title}
                description={info[2].description}
                pillText="Deploy"
            />
            </CardContainer>
        </SimpleGrid>
        <a href="https://www.producthunt.com/posts/smartpress-ai?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-smartpress&#0045;ai" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=408569&theme=neutral" alt="SmartPress&#0046;ai - Magic&#0032;smart&#0032;contract&#0032;builder&#0032;platform | Product Hunt" style={{width:'250px',height: '54px',width:"250",height:"54", marginBottom:'16px' }} /></a>

      </SignedOut>
    );
}

const DashboardInfo = () => {

  const info = [
    {
      title: "Build a EOS EVM app in seconds",
      description: "SmartPress writes Ethereum app code based on your prompts. Under the hood, the tailored SmartPress AI engineering makes it suitable for new and experienced blockchain builders. Deploy direct to EOS EVM"
    },
    {
      title: "GPT.4.2.",
      description: "Introducing our groundbreaking feature that utilizes GPT-4 AI to automatically write advanced solidity contracts for you. Say goodbye to tedious coding jargon!"
    },
    {
      title: "Smart Bots.",
      description: "We give you a Ethereum app that is ready to go. Offering features like SmartBots, robots that automatically attempt to fix your code where other AI tools would output error laden code."
    }
  ]

  return (
    <span>
      {CardGrid(info)}

      <SimpleGrid mb="0" columns={[1, 1, 4]} spacing="2"></SimpleGrid>
    </span>
  );
};

export default DashboardInfo;