import { useLocation, Link } from 'react-router-dom';
import { useState, useEffect } from "react"
import { Box, Button, Flex, Heading, Text, VStack, Stack } from '@chakra-ui/react';
import { Table } from "../Table/Table"
import { chains } from "../../config/chains"
import { useMediaQuery } from '@chakra-ui/react';
import { InteractContract } from '../InteractContract/InteractContract';

export const CreateSuccess = () => {

    const [codeAbi, setCodeAbi] = useState(null)

    const [isLargerThanMobile] = useMediaQuery('(min-width: 850px)');

    const [params, setParams] = useState(false)

    const location = useLocation();

    useEffect(() => {
      const searchParams = new URLSearchParams(location.search);

      console.log(searchParams)
      const paramsObj = {};
  
      for (const [key, value] of searchParams) {
        if (key != "logsBloom" && key != "logs") {
            paramsObj[key] = value;
        }
      }

      paramsObj.compiler_version = "0.8.18"

      setParams(paramsObj)

      // do something with the params object...
    }, [location.search]);

    const fetchCode = async () => {

        console.log(params.requestId)

        const response = await fetch('https://' + process.env.REACT_APP_API_URL + '/getContract', {
            method: 'POST',
            body: JSON.stringify({id: params.requestId}),
            timeout: 30
          })
          
        const data = await response.json();

        setCodeAbi(JSON.parse(data.message.contents.S).contractBytes.abi);

        // console.log(data.message.contents)

        // console.log(data.message.contents ? JSON.parse(data.message.contents.S).contractBytes.abi : {})
    };

    useEffect(() => {
        fetchCode()
    }, [params])

    console.log(params)

    console.log(chains())

    return (
        <VStack>
            <Stack direction={isLargerThanMobile ? "row" : "column"}>
        
                <Box width="650px" bg="white" p={8} borderRadius={8} boxShadow="md">
                    <Heading as="h1" size="xl" mb={10}>
                    Contract Deployed
                    </Heading>

                    <Box bg="white" p={8} borderRadius={8}>
                        <Flex justify="center">

                        <a href={"../../../../create/ai/results/" + params.requestId} target="_blank">
                            <Button
                                size="lg"
                                mr={4}
                                onClick={() => console.log('Send Transaction')}
                            >
                                View code
                            </Button>
                        </a> 

                        {/* TODO make this for all chains.. get all explorer urls */}
                        { console.log(chains()[params.network])}
                        {chains()[params.network]?.blockExplorers ? <a href={chains()[params.network]?.blockExplorers?.default?.url + "/address/" + params.contractAddress} target="_blank">
                            <Button
                                size="lg"
                                mr={4}
                            >
                                View on a block explorer
                            </Button>
                        </a> : ""}

                        
                        {/* { isLargerThanMobile ? <a href={"../../../../integrate/" + "erc777" + "/" + params.contractAddress} target="_blank">
                            <Button
                                size="lg"
                                mr={4}
                            >
                                Integrate it into your product
                            </Button>
                        </a> : "" } */}
                    
                        </Flex>
                    </Box>

                    <Heading as="h2" size="lg" mb={6}>
                    Deployment details
                    </Heading>

                    <Flex mb="20" justify="center">
                        {params ? <Table data={{...params, "Verify steps": "View code, copy flattened bytecode to clipboard, in your preffered block explorer navigate to verification screen (usually under code), select compiler version 0.8.18, optimizer: TRUE, Runs: 200, choose preffered licence, paste flat code, verification should complete."}}/> : <></>}
                    </Flex>
                </Box>
         
                <Box>
                    {codeAbi ? <InteractContract abi={codeAbi} address={params.contractAddress}></InteractContract> : <></>}
                </Box>
            </Stack>
        
        {/* </Flex> */}
        </VStack>
    );
  };
