import { Box, SimpleGrid, useBreakpointValue, Button, Link, useMediaQuery } from "@chakra-ui/react";
import { Card } from "../Card/Card"
import { SignedIn, SignedOut, UserButton, useClerk, SignInButton, SignOutButton } from "@clerk/clerk-react";
import Discord from "../Icons/Discord";
import Polygon from "../Icons/Polygon";

const CardContainer = ({ children }) => {
  const columnCount = useBreakpointValue({ base: 1, md: 3 });
  
  return (
    <Box bg="white" borderRadius="lg" overflow="hidden" mb={{ base: 4, md: 0 }}>
      {children}
    </Box>
  );
};

const CardGrid = (info) => {

  const [isLargerThanMobile] = useMediaQuery('(min-width: 480px)');


    return (
      // <SignedOut>
        <SimpleGrid mb="5" columns={[1, null, 3]} spacing="6">
            <CardContainer>
            <Card
                title={info[0].title}
                description={info[0].description}
                pillText="Build"
                link={{url: "/create", text: "Start Buidling"}}
            />
            </CardContainer>
            <CardContainer>
            <Card
                title={info[1].title}
                description={info[1].description}
                pillText="Compile"
            />
            </CardContainer>
            <CardContainer>
            <Card
                title={info[2].title}
                description={info[2].description}
                pillText="Deploy"
            />
            </CardContainer>
        </SimpleGrid>


        /* <div style={{display: isLargerThanMobile ? "flex" : "", alignItems: "center"}}>
          <a target="_blank" href="https://forms.gle/Z9rvoPhz6hgY4b5r6">
            <Button 
                // mt="2"
                mr="2"
                mb="4"
                colorScheme="blue"
                size="lg"
            >
                Get expert help for your project
            </Button>
          </a>     

          <a href="https://www.producthunt.com/posts/smartpress-ai?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-smartpress&#0045;ai" target="_blank">
            <img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=408569&theme=neutral" alt="SmartPress&#0046;ai - Magic&#0032;smart&#0032;contract&#0032;builder&#0032;platform | Product Hunt" style={{width:"250px",height: "54px", marginBottom: "16px"}} />
          </a>
          
          <Link href="https://wiki.polygon.technology/docs/pos/deploy/smartpress/" isExternal>
            <Button mt="0" mb="4" ml="2" size="lg" style={{background:"rgba(0,0,0,0.3", color:"white"}}>Used by {Polygon()} community</Button>
          </Link>
        </div> */



      // </SignedOut>
    );
}

const DashboardInfo = () => {

  const info = [
    {
      title: "AI for creating Ethereum applications",
      description: "EthCreator writes code based on your prompts. Under the hood, the tailored Solidity AI engineering makes it suitable for new and experienced blockchain builders."
    },
    {
      title: "GPT.4.2.",
      description: "Introducing our groundbreaking feature that utilizes GPT-4 AI to automatically write solidity contracts for you. Say goodbye to tedious code writing."
    },
    {
      title: "Smart Bots.",
      description: "We give you a Ethereum app that is ready to go. Offering features like SmartBots, robots that automatically attempt to fix your code where other AI tools would output error laden code."
    }
  ]

  return (
    <span>
      {CardGrid(info)}

      <SimpleGrid mb="0" columns={[1, 1, 4]} spacing="2"></SimpleGrid>
    </span>
  );
};

export default DashboardInfo;