import React, { useState } from 'react';
import { ethers } from 'ethers';
import Wagmi, { useProvider, useSigner } from 'wagmi';
import { Box, Button, Container, FormControl, FormLabel, Grid, Heading, Input, Stack, Text, VStack } from '@chakra-ui/react';


export function InteractContract({ abi, address }) {

  const provider = useProvider("https://api.testnet.evm.eosnetwork.com/")
  const {data: signer} = useSigner()

  const contract = new ethers.Contract(address, abi, provider);
  
    const [method, setMethod] = useState(null);
    const [inputs, setInputs] = useState([]);
    const [output, setOutput] = useState(null);
    const [error, setError] = useState(null);
  
  
    const handleMethodClick = (selectedMethod) => {
      setMethod(selectedMethod);
      setInputs(Array(selectedMethod.inputs.length).fill(''));
      setOutput(null);
      setError(null);
    };
  
    const handleInputChange = (event, index) => {
      const newInputs = [...inputs];
      newInputs[index] = event.target.value;
      setInputs(newInputs);
    };
  
    const handleMethodCall = async () => {
      try {
        const contractMethods = new ethers.Contract(address, abi, signer);
        const result = await contractMethods[method.name](...inputs);
        setOutput(result.toString());
        setError(null);
      } catch (err) {
        setOutput(null);
        setError(err.message);
      }
    };
  
    const methodButtons = contract.interface.fragments.map((fragment, index) => (
      <Button key={index} variant="outline" size="sm" onClick={() => handleMethodClick(fragment)}>
        {fragment.name}
      </Button>
    ));
  
    return (
      <Container mb="20" maxW="lg">
        <Box p={5} backgroundColor="white" borderWidth="1px" borderRadius="lg">
          <Heading as="h1" size="lg" mb={4}>
            Interact With Your Ethereum application
          </Heading>

          <Heading as="h1" size="md" mb={4}>
            Options
          </Heading>

          <Stack spacing={6}>
          {method && (
              <Box borderWidth="1px" borderRadius="lg" p={5}>
                <Text fontSize="lg" fontWeight="bold" mb={4}>{method.name}</Text>
                {method.inputs.map((input, index) => (
                  <FormControl key={index} mb={4}>
                    <FormLabel>{input.name} ({input.type})</FormLabel>
                    <Input
                      placeholder={`${input.name} (${input.type})`}
                      value={inputs[index]}
                      onChange={(event) => handleInputChange(event, index)}
                    />
                  </FormControl>
                ))}
                <Button colorScheme="teal" size="md" onClick={handleMethodCall}>Call</Button>
              </Box>
            )}
            
            <Grid templateColumns="repeat(1, 1fr)" gap={2}>
              {methodButtons.map((button, index) => (
                <React.Fragment key={index}>
                  {button}
                  {((index + 1) % 5 === 0) && <div />} {/* Add an empty div after every 5th button */}
                </React.Fragment>
              ))}
            </Grid>
            
            {output && (
              <Box borderWidth="1px" borderRadius="lg" p={5}>
                <Text fontSize="lg" fontWeight="bold" mb={4}>Output</Text>
                <Text>{output}</Text>
              </Box>
            )}
            {error && (
              <Box borderWidth="1px" borderRadius="lg" p={5}>
                <Text fontSize="lg" fontWeight="bold" mb={4}>Error</Text>
                <Text color="red.500">{error}</Text>
              </Box>
            )}
          </Stack>
        </Box>
      </Container>
    );
  }
