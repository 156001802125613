import { Alert, AlertIcon, AlertTitle, CloseButton } from "@chakra-ui/react";
import { Children, useState } from "react";

export const AlertBanner = ({ status, title, message, children }) => {
  const [isClosed, setIsClosed] = useState(false);

  const handleClose = () => {
    setIsClosed(true);
  };

  if (isClosed) {
    return null;
  }

  return (
    <Alert status={status} variant="subtle" alignItems="center">
      <AlertIcon />
      <AlertTitle mr={2}>{title}</AlertTitle>
      {message}
      {children}
      <CloseButton ml="auto" onClick={handleClose} />
    </Alert>
  );
};

export default AlertBanner;
