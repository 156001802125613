import { useEffect, useState } from "react";
import { SignedIn, SignedOut, UserButton, useClerk, SignInButton, SignOutButton } from "@clerk/clerk-react";
import { useAuth } from "@clerk/clerk-react";
import { getCredits } from '../../libs/sp';

export const BuildCreditsText = () => {

    const { isLoaded, userId, sessionId, getToken } = useAuth();
    const [ creditsUsed, setCreditsUsed ] = useState(0)

    const checkCredits = async () => {
        console.log(sessionId)
        const user_token = await getToken();
        let credits = await getCredits(user_token, sessionId)
        credits = JSON.parse(credits)
        if (credits?.message?.credits_used) {
            setCreditsUsed(credits.message.credits_used)
        }
        console.log(credits)
    }

    useEffect(() => {
        if (isLoaded) {
            checkCredits()
        }
    }, [isLoaded])

    return (
        // <SignedIn>
        <div style={{marginTop:"10px"}}>
            <b>{1 - creditsUsed}</b> free build credit{1 - creditsUsed != 1 ? 's' : ''} <b>left today. </b> 
            
            { 1 - creditsUsed == 0 ? <SignedOut><SignInButton><span style={{textDecoration:"underline", cursor:"pointer"}}>Sign in</span></SignInButton> for another free credit.</SignedOut> : ""}
            { 1 - creditsUsed == 0 ? <SignedIn><span>Wait until <b>tomorrow</b> for another credit</span></SignedIn> : ""}
        </div>
        
        // </SignedIn>
    );
};

export default BuildCreditsText;

