const generateCode = async (requirements, token, user_token, user_session) => {
    const prompt = `${requirements}`;

    console.log(prompt)

    const response = await fetch('https://' + process.env.REACT_APP_API_URL + '/XgenCode382', {
        method: 'POST',
        body: JSON.stringify({prompt, token, user_token, user_session}),
        timeout: 30
      })

    const data = await response.json();
    const botMessage = JSON.stringify(data)

    return botMessage;
};

const getCredits = async (user_token, user_session) => {

  let signedIn = false;
  if (user_token && user_session) {
    signedIn = true
  }

  const response = await fetch('https://' + process.env.REACT_APP_API_URL + '/getCredits', {
      method: 'POST',
      body: signedIn ? JSON.stringify({user_token, user_session}) : JSON.stringify({"signedIn": false}),
      timeout: 30
    })

  const data = await response.json();
  const botMessage = JSON.stringify(data)

  return botMessage;
};

export { generateCode, getCredits };
