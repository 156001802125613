import { Box, Heading, Text, SimpleGrid, Button, useMediaQuery } from "@chakra-ui/react";
import Pill from "../Pill/Pill"
import { Link as RouterLink } from "react-router-dom";

export function Card({ title, pillText, type, symbol, description, to, link }) {
  const [isLargerThanMobile] = useMediaQuery('(min-width: 600px)');

  return (
    <Box
      bg="white"
    //   boxShadow="md"
      borderRadius="md"
      p={6}
      mx="auto"
    >
      <SimpleGrid spacing="6">
        <Heading size="lg" mb={2} minHeight="40px"  colSpan={[1, 2]}>
            <b style={{fontWeight: 800}}>{title}</b>
        </Heading>
        {/* <div style={{position:"relative", top: '-12px', right: 0}}>{ pillText ? <Pill>{pillText}</Pill> : ""}</div> */}
      </SimpleGrid>

      <Box mb="4">
        {description ? <span>{description}</span> : ""} {" "}

        {link?.text && !isLargerThanMobile ? <a style={{textDecoration: "underline", fontWeight:800}} href={link?.url}>{link.text}</a> : ""}
      </Box>

      { to ? <Box display="flex" justifyContent="space-between">
        <Button colorScheme="blue" mt="3" width="100%" to={to} as={RouterLink}>Start</Button>
      </Box> : ""}
    </Box>
  );
}