import { useState } from 'react';
import { Flex, Box, Text, Button, Wrap, WrapItem, Heading } from '@chakra-ui/react';
import { env } from '../../libs/env'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

function PillBot() {
  const [language, setLanguage] = useState('');
  const [serviceType, setServiceType] = useState('');
  const [codeSnippet, setCodeSnippet] = useState('');
  const [loading, setLoading] = useState(false);

  const languageOptions = [
    'JavaScript',
    'Python',
    'Ruby',
    'Java',
    'C#',
    'PHP',
    'Swift',
    'Kotlin',
    'Go',
    'Rust',
    'TypeScript',
    'Scala'
  ];

  const serviceTypeOptions = ['Game', 'SaaS', 'Crypto', 'Other'];

  const handleLanguageSelect = (option) => {
    setLanguage(option);
  };

  const handleServiceTypeSelect = (option) => {
    setServiceType(option);
  };

  const generateCodeSnippet = async () => {
    const prompt = `Generate example code for integrating a EVM ERC721 for a ${serviceType} written in ${language}. Dont wrap the code in quotes or the name of the language around the code. just put the code. I dont want excuses, just try to generate the code the best you can. You dont neex to execute it or anything, just help guide the user on how to write it themself.`;
    setLoading(true)
    const response = await fetch('https://api.openai.com/v1/chat/completions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${env.REACT_APP_AI_API_KEY}`
      },
      body: JSON.stringify({
        model: 'gpt-3.5-turbo',
        messages: [{ role: 'user', content: prompt }],
        temperature: 0.7,
        n: 1,
      })
    });
    const data = await response.json();
    setLoading(false)
    console.log(data)
    const botMessage = data.choices[0].message.content.trim();
    setCodeSnippet(botMessage);
  };

  return (
    <Box p={4}>
      <Flex flexDirection="column" mb={4}>
        <Heading pb="5" size="xl" mb={2}>SmartPress integration assistant</Heading>
        <Text fontSize="xl" mb={2}>Pick a programming language:</Text>
        <Wrap spacing={2}>
          {languageOptions.map((option) => (
            <WrapItem key={option}>
              <Button
                variant={language === option ? "solid" : "outline"}
                colorScheme={language === option ? "blue" : "none"}
                onClick={() => handleLanguageSelect(option)}
              >
                {option}
              </Button>
            </WrapItem>
          ))}
        </Wrap>
      </Flex>
      <Flex mb="30" flexDirection="column" mb={4}>
        <Text fontSize="xl" mb={2}>What type of service is it?</Text>
        <Wrap spacing={2}>
          {serviceTypeOptions.map((option) => (
            <WrapItem key={option}>
              <Button
                variant={serviceType === option ? "solid" : "outline"}
                colorScheme={serviceType === option ? "blue" : "none"}
                onClick={() => handleServiceTypeSelect(option)}
              >
                {option}
              </Button>
            </WrapItem>
          ))}
        </Wrap>
      </Flex>
      <Button
        colorScheme="blue"
        onClick={generateCodeSnippet}
        disabled={!language || !serviceType}
      >
        Generate code snippet
      </Button>
      { loading ? <p>Loading code example...</p> : ""}
      {codeSnippet && (
        <Box mt={4}>
          <Text fontSize="xl" mb={2}>Code Snippet:</Text>
          <SyntaxHighlighter language={language.toLowerCase()} style={materialDark}>
            {codeSnippet}
          </SyntaxHighlighter>
        </Box>
      )}
    </Box>
  );
}

export default PillBot;
