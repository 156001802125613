import React from "react";

/* USAGE <Link href="https://discord.gg/J3pCxDTn" isExternal><Button mt="1.5" mb="4" ml="2" outline={"solid"} leftIcon={Discord} color={"#7289DA"}><Discord/>Join the Discord</Button></Link> */


function Discord() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 48 48"
      style={{marginRight:"10px"}}
    >
      <path
        fill="#8c9eff"
        d="M40 12s-4.585-3.588-10-4l-.488.976C34.408 10.174 36.654 11.891 39 14c-4.045-2.065-8.039-4-15-4s-10.955 1.935-15 4c2.346-2.109 5.018-4.015 9.488-5.024L18 8c-5.681.537-10 4-10 4s-5.121 7.425-6 22c5.162 5.953 13 6 13 6l1.639-2.185C13.857 36.848 10.715 35.121 8 32c3.238 2.45 8.125 5 16 5s12.762-2.55 16-5c-2.715 3.121-5.857 4.848-8.639 5.815L33 40s7.838-.047 13-6c-.879-14.575-6-22-6-22zM17.5 30c-1.933 0-3.5-1.791-3.5-4s1.567-4 3.5-4 3.5 1.791 3.5 4-1.567 4-3.5 4zm13 0c-1.933 0-3.5-1.791-3.5-4s1.567-4 3.5-4 3.5 1.791 3.5 4-1.567 4-3.5 4z"
      ></path>
    </svg>
  );
}

export default Discord;
