import { env } from "../libs/env"
import { mainnet, goerli, sepolia, arbitrum, arbitrumGoerli, optimism, bsc, bscTestnet, polygon, polygonMumbai, polygonZkEvm, polygonZkEvmTestnet } from 'wagmi/chains'

export const CHAINS = {
    sepolia,
    base_testnet: {
        id: 84531,
        name: 'Base Testnet',
        network: 'base_testnet',
        nativeCurrency: {
            decimals: 18,
            name: 'Base Ethereum',
            symbol: 'ETH',
        },
        rpcUrls: {
            public: { http: ['https://goerli.base.org'] },
            default: { http: ['https://goerli.base.org'] },
        },
        blockExplorers: {
            etherscan: { name: 'Base Goerli testnet', url: 'https://goerli.basescan.org/' },
            default: { name: 'Base Goerli', url: 'https://goerli.basescan.org/' },
        },
    },
    // base_mainnet: {
    //     id: 8453,
    //     name: 'Base Mainnet',
    //     network: 'base_mainnet',
    //     nativeCurrency: {
    //         decimals: 18,
    //         name: 'Base Ethereum',
    //         symbol: 'ETH',
    //     },
    //     rpcUrls: {
    //         public: { http: ['https://mainnet.base.org'] },
    //         default: { http: ['https://mainnet.base.org'] },
    //     },
    //     blockExplorers: {
    //         etherscan: { name: 'EVM testnet', url: 'https://basescan.org/' },
    //         default: { name: 'EVM testnet', url: 'https://basescan.org/' },
    //     },
    // },
    eos_testnet: {
        id: 15557,
        name: 'EOS Testnet',
        network: 'eos_testnet',
        nativeCurrency: {
            decimals: 18,
            name: 'EOS_testnet',
            symbol: 'EOS',
        },
        rpcUrls: {
            public: { http: ['https://api.testnet.evm.eosnetwork.com/'] },
            default: { http: ['https://api.testnet.evm.eosnetwork.com/'] },
        },
        blockExplorers: {
            etherscan: { name: 'EVM testnet', url: 'https://explorer.testnet.evm.eosnetwork.com/' },
            default: { name: 'EVM testnet', url: 'https://explorer.testnet.evm.eosnetwork.com/' },
        },
    },
    // eos_mainnet: {
    //     id: 17777,
    //     name: 'EOS Mainnet',
    //     network: 'eos_mainnet',
    //     nativeCurrency: {
    //         decimals: 18,
    //         name: 'EOS',
    //         symbol: 'EOS',
    //     },
    //     rpcUrls: {
    //         public: { http: ['api.evm.eosnetwork.com'] },
    //         default: { http: ['api.evm.eosnetwork.com'] },
    //     },
    //     blockExplorers: {
    //         etherscan: { name: 'EVM testnet', url: 'https://explorer.evm.eosnetwork.com' },
    //         default: { name: 'EVM testnet', url: 'https://explorer.evm.eosnetwork.com' },
    //     },
    // },
    // mainnet, 
    goerli, 
    // arbitrum, 
    arbitrumGoerli, 
    // optimism, 
    // bsc, 
    bscTestnet, 
    // polygon, 
    polygonMumbai, 
    // polygonZkEvm, 
    polygonZkEvmTestnet
}

export const chains = () => {
    if (env.REACT_APP_VERCEL_STAGE === "DEV")
        return CHAINS
    if (env.REACT_APP_VERCEL_STAGE === "PROD") {
        return CHAINS
    } else {
        return CHAINS
    }
}
