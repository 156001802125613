import { useEffect } from "react"
import { Box, Flex, Heading, Text, Center, useMediaQuery } from "@chakra-ui/react";

import TermsAndConditions from "../T&C/Terms&Conditions";
import TwitterLink from "../TwitterLink/TwitterLink";
import { useAuth } from "@clerk/clerk-react";
import { Login, Logout } from "../Clerk/Clerk";
import { Web3Button, Web3Modal, Web3NetworkSwitch } from "@web3modal/react"



const { wagmiClient, ethereumClient, projectId } = require("../../libs/wagmi")

export const AppContainer = ({ children }) => {
  const [isLargerThanMobile] = useMediaQuery('(min-width: 480px)');


  const { isLoaded, userId, sessionId, getToken } = useAuth();
  console.table({ isLoaded, userId, sessionId, getToken })

  return (
    <Box bg="gray.100" w="100%" minHeight="100vh">
      <Header />
      <Box maxW="7xl" mx="auto" mt="4" px={{ base: "6", md: "8" }}>
        {children}
      </Box>
      <Footer/>
    </Box>
  );
};

const Header = () => {

  const [isLargerThanMobile] = useMediaQuery('(min-width: 480px)');

    return (
      <Box bg="blue.500" w="100%" height="80px" position="sticky" top="0" zIndex="sticky">
        <Box maxW="7xl" mx="auto" px={{ base: "6", md: "8" }}>
          <Flex
            h="16"
            alignItems="center"
            justifyContent="space-between"
          >
            <a href="../../../../">
                <Heading letterSpacing="-1.2px" mt="10px" size={isLargerThanMobile ? "lg" : "md"} color="white">EthCreator</Heading>
            </a>
            <Flex alignItems="right">

              <div style={isLargerThanMobile ? {marginRight:"20px", marginTop:"16px", color:"white"} : {color:"white", marginTop:"20px", marginLeft:'20px', transform:"scale(0.9)"}}>
                <TwitterLink></TwitterLink>
              </div>

              {/* <div style={isLargerThanMobile ? {} : {transform: "scale(0.7)", marginLeft:"-10px", marginTop: "4px"}}><Login></Login></div>
              <div style={{marginTop: "6px"}}><Logout></Logout></div> */}

              <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
            </Flex>
          </Flex>

          
        </Box>
      </Box>
    );
  };


  const Footer = () => {

    const [isLargerThanMobile] = useMediaQuery('(min-width: 480px)');
    
    return (
      <Box style={{zIndex: 0}} bg="blue.500" w="100%" position="fixed" bottom="0" zIndex="sticky">
        <Box maxW="7xl" mx="auto" px={{ base: "6", md: "8" }}>
          <Center>
            <p style={{fontSize:"10px", color:"white", height:"30px", paddingTop:"5px"}} >Ethereum app creator may produce inaccurate, non functional or insecure code. Use with caution.</p>
          </Center>
        </Box>
        <Box maxW="7xl" mx="auto" px={{ base: "6", md: "8" }}>
          <Flex
            h="8"
            alignItems="center"
            justifyContent="space-between"
          >
            {/* { isLargerThanMobile ? <Text color="white">SmartPress </Text> : ""} */}
            <TermsAndConditions></TermsAndConditions>
            { isLargerThanMobile ? <Text mr="10" color="white">smartpressau@gmail.com</Text> : ""}
          </Flex>
        </Box>
      </Box>
    );
  };