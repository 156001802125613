import { Button, useClipboard } from "@chakra-ui/react";

const CopyButton = ({ text, moveUp }) => {
  const { hasCopied, onCopy } = useClipboard(text);

  return (
    <Button ml="2" style={{top: moveUp ? "-75px" : "0" }} onClick={onCopy} size="sm">
      {hasCopied ? "Copied!" : "Copy"}
    </Button>
  );
};

export default CopyButton;
