

import React from 'react';
import { CreateContract_ } from "../components/CreateContract/CreateContract_";
import { useParams } from 'react-router-dom';

function CreateContract() {
  const { contractId } = useParams();

  return (
    <CreateContract_ title={contractId}/>
  );
}
export default CreateContract;
