import React, { useState } from 'react';
import { Button, Text } from '@chakra-ui/react';
import { ModalComponent } from '../Modal/Modal';
import { ChakraProvider, Box, Heading,  UnorderedList, ListItem } from '@chakra-ui/react';

const TermsAndConditions = ({color, text}) => {
  const [accepted, setAccepted] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);


  const handleCheckboxChange = () => {
    setAccepted(!accepted);
  };

  const handleAcceptButtonClick = () => {
    if (accepted) {
      // Perform the necessary actions upon accepting the terms
      console.log('Terms accepted!');
      handleModalClose()
    } else {
      // Display an error or warning message
      console.log('Please accept the terms to proceed.');
    }
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <div>
      <Text style={{cursor:"pointer", textDecoration:"underline", fontSize:"12px", color: color ? color : "white"}} size="sm" variant="outline" onClick={() => setModalOpen(true)}>
        {text ? text : "Terms and Conditions"}
      </Text>
      <ModalComponent
        open={modalOpen}
        title="Terms and Conditions"
        onClose={handleModalClose}
        fullScreen="true"
      >
        {/* <h1>Smart Press Contract Creation App</h1> */}
        <div style={{height:"0px",width:"0px", opacity:0 }}><input type="checkbox"/></div>
        <p style={{height:"70vh", overflow:"scroll"}}>
        <Box p={4} maxW="800px" fontFamily="Arial, sans-serif">
          <Heading as="h1" fontSize="24px" fontWeight="bold" mb={4}>
            Terms and Conditions of Use for the Smartpress (EthCreator) Solidity Contract Creation App - formerly known as SmartPress
          </Heading>
          <Text lineHeight="1.6" mb={4}>
            These terms and conditions ("Terms") govern your use of the Smartpress (EthCreator) Ethereum app creation application ("the App"), developed by SMARTPRESS PTY LTD ("the Developer"). The App utilizes artificial intelligence and machine learning algorithms to generate code snippets, scripts, or other programming materials (referred to as "Generated Code"). By accessing or using the App, you agree to be bound by these Terms. If you do not agree to these Terms, please refrain from using the App.
          </Text>

          <Heading as="h2" fontSize="20px" fontWeight="bold" mt={4} mb={2}>
            App Purpose and Legal Compliance:
          </Heading>
          <UnorderedList mb={4} pl={6}>
            <ListItem>
              1.1 The App is designed to assist users in creating Generated Code for various purposes. It is essential to note that the App should only be used for fully legal use cases of Generated Code.
            </ListItem>
            <ListItem>
              1.2 The responsibility lies with the user to ensure compliance with their respective jurisdiction's laws and regulations when creating and executing Generated Code using the App.
            </ListItem>
            <ListItem>
              1.3 The Developer does not provide legal advice, and the use of the App does not create an attorney-client relationship. Users are advised to seek legal counsel to ensure their Generated Code complies with applicable laws.
            </ListItem>
            <ListItem>
              1.4 It is important to understand that the Generated Code may not be functional, secure, or suitable for any specific purpose. The Generated Code is provided on an "as-is" basis and should be used with caution.
            </ListItem>
          </UnorderedList>

          <Heading as="h2" fontSize="20px" fontWeight="bold" mt={4} mb={2}>
            User Responsibility:
          </Heading>
          <UnorderedList mb={4} pl={6}>
            <ListItem>
              2.1 You acknowledge that you are solely responsible for your use of the App and any Generated Code created using the App.
            </ListItem>
            <ListItem>
              2.2 You agree to use the App in accordance with all applicable laws, regulations, and third-party rights.
            </ListItem>
            <ListItem>
              2.3 You shall not use the App for any unlawful, fraudulent, or unauthorized purposes.
            </ListItem>
            <ListItem>
              2.4 You shall not use the App to create Generated Code that infringes upon the intellectual property rights of any third party.
            </ListItem>
            <ListItem>
              2.5 You shall not use the App to engage in any activity that could cause harm, damage, or disruption to the App, the Developer, or any other users.
            </ListItem>
          </UnorderedList>

          <Heading as="h2" fontSize="20px" fontWeight="bold" mt={4} mb={2}>
            Security and Code Audits:
          </Heading>
          <UnorderedList mb={4} pl={6}>
            <ListItem>
              3.1 The App may provide Generated Code that is not secure or may not work as intended. You understand and acknowledge this risk.
            </ListItem>
            <ListItem>
              3.2 It is highly recommended that you obtain external audits and conduct thorough reviews of the Ethereum app code before deploying them into production or executing them.
            </ListItem>
            <ListItem>
              3.3 The Developer shall not be liable for any loss, damage, or legal consequences arising from the use of Generated Code generated by the App.
            </ListItem>
          </UnorderedList>

          <Heading as="h2" fontSize="20px" fontWeight="bold" mt={4} mb={2}>
            Intellectual Property:
          </Heading>
          <UnorderedList mb={4} pl={6}>
            <ListItem>
              4.1 The App and all associated intellectual property rights belong to the Developer. These include, but are not limited to, trademarks, copyrights, logos, and trade secrets.
            </ListItem>
            <ListItem>
              4.2 The Developer grants you a limited, non-exclusive, non-transferable, and revocable license to use the App solely for its intended purpose.
            </ListItem>
            <ListItem>
              4.3 Any Generated Code produced by the app may include copyright protected material that is inadvertently learned by the AI model. It's your responsibility to ensure that you conform to laws around the copyright of any Generated Code this app produces.
            </ListItem>
          </UnorderedList>

          <Heading as="h2" fontSize="20px" fontWeight="bold" mt={4} mb={2}>
            Limitation of Liability:
          </Heading>
          <UnorderedList mb={4} pl={6}>
            <ListItem>
              5.1 To the extent permitted by applicable law, the Developer shall not be liable for any direct, indirect, incidental, consequential, or special damages arising out of or in connection with your use or inability to use the App, including but not limited to damages for loss of profits, data, or business opportunities.
            </ListItem>
            <ListItem>
              5.2 The Developer shall not be liable for any errors, omissions, inaccuracies, or deficiencies in the App or the Generated Code created using the App.
            </ListItem>
          </UnorderedList>

          <Heading as="h2" fontSize="20px" fontWeight="bold" mt={4} mb={2}>
            Modifications and Termination:
          </Heading>
          <UnorderedList mb={4} pl={6}>
            <ListItem>
              6.1 The Developer reserves the right to modify, suspend, or discontinue the App or any part thereof, at any time and without prior notice.
            </ListItem>
            <ListItem>
              6.2 The Developer may terminate or suspend your access to the App, in whole or in part, if you violate these Terms or engage in any prohibited activities.
            </ListItem>
          </UnorderedList>

          <Heading as="h2" fontSize="20px" fontWeight="bold" mt={4} mb={2}>
            Governing Law and Jurisdiction:
          </Heading>
          <UnorderedList mb={4} pl={6}>
            <ListItem>
              7.1 These Terms shall be governed by and construed in accordance with the laws of Australia. Any dispute arising out of or relating to these Terms or the use of the App shall be subject to the exclusive jurisdiction of the courts in Australia.
            </ListItem>
          </UnorderedList>

          <Heading as="h2" fontSize="20px" fontWeight="bold" mt={4} mb={2}>
            Severability:
          </Heading>
          <UnorderedList mb={4} pl={6}>
            <ListItem>
              8.1 If any provision of these Terms is found to be invalid, illegal, or unenforceable, the remaining provisions shall continue in full force and effect.
            </ListItem>
          </UnorderedList>

          <Heading as="h2" fontSize="20px" fontWeight="bold" mt={4} mb={2}>
            Entire Agreement:
          </Heading>
          <UnorderedList mb={4} pl={6}>
            <ListItem>
              9.1 These Terms constitute the entire agreement between you and the Developer regarding the use of the App and supersede any prior or contemporaneous agreements, understandings, or representations, whether oral or written, relating to the App.
            </ListItem>
          </UnorderedList>

          <Heading as="h2" fontSize="20px" fontWeight="bold" mt={4} mb={2}>
            Modifications:
          </Heading>
          <UnorderedList mb={4} pl={6}>
            <ListItem>
              10.1 The Developer reserves the right to modify or update these Terms at any time without prior notice. It is your responsibility to review these Terms periodically for any changes.
            </ListItem>
            <ListItem>
              10.2 By continuing to use the App after any modifications to these Terms, you acknowledge and accept the updated Terms.
            </ListItem>
          </UnorderedList>

          <Heading as="h2" fontSize="20px" fontWeight="bold" mt={4} mb={2}>
            Waiver:
          </Heading>
          <UnorderedList mb={4} pl={6}>
            <ListItem>
              11.1 The failure of the Developer to enforce any right or provision of these Terms shall not be deemed a waiver of such right or provision. Any waiver of any provision of these Terms will be effective only if it is in writing and signed by the Developer.
            </ListItem>
          </UnorderedList>

          <Heading as="h2" fontSize="20px" fontWeight="bold" mt={4} mb={2}>
            Contact Information:
          </Heading>
          <UnorderedList mb={4} pl={6}>
            <ListItem>
              12.1 If you have any questions, concerns, or feedback regarding these Terms or the App, please contact the Developer at smartpressau@gmail.com.
            </ListItem>
          </UnorderedList>
        </Box>
        </p>
        <div style={{marginTop:"10px", marginBottom:"10px", paddingLeft:"5px", backgroundColor:'silver', borderRadius:'4px'}}>
            <label>
                <input
                    style={{marginRight:"5px", }}
                    type="checkbox"
                    checked={accepted}
                    onChange={handleCheckboxChange}
                />
                I have read and agree to the terms and conditions.
            </label>
        </div>
        <Button colorScheme={accepted ? 'gray' : ''} onClick={handleAcceptButtonClick} disabled={!accepted}>
            Accept
        </Button>
      </ModalComponent>

    </div>
  );
};

export default TermsAndConditions;