import { useEffect, useState } from 'react';
import { Flex, Box, Text, Button, Input,Textarea, WrapItem, Heading, BeatLoader, FormControl, FormLabel, useMediaQuery } from '@chakra-ui/react';
import { env } from '../../libs/env'
import { generateCode, getCredits } from '../../libs/sp';
import { removeQuotesFromString } from '../../libs/string'
import { PollingForCode } from '../PollingForCode/PollingForCode';
import PromptingForm from '../PromptingForm/PromptingForm'
import RecaptchaV3 from '../Recaptcha/Recaptcha';
import InspirationModal from '../Inspiration/Inspiration';
import TermsAndConditions from '../T&C/Terms&Conditions';
import BuildCreditsText from '../BuildCredits/BuildCreditsText';

import { useAuth } from "@clerk/clerk-react";


function CodeBot(props) {

  const [isLargerThanMobile] = useMediaQuery('(min-width: 480px)');
  const [codeSnippet, setCodeSnippet] = useState('');
  const [loading, setLoading] = useState(false);
  const [requirements, setRequirements] = useState('');
  const [promptForm, setPromptForm] = useState('');
  const [confimred, setConfimed] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [captchaKey, setCaptchaKey] = useState(false);

  const { isLoaded, userId, sessionId, getToken } = useAuth();

  const handleChange = (event) => {
    setRequirements(event.target.value);
  };

  const handlePromptFormChange = (value) => {
    setPromptForm(value);
  };

  const handleConfirmRequirements = () => {
    setConfimed(!confimred);
  };

  const handleTermsCheckChange = () => {
    setAccepted(!accepted);
  };

  const generateCodeSnippet = async () => {
    setLoading(true)
    const user_token = await getToken();
    const generateRequest = await generateCode(promptForm + requirements, "TEMP", user_token, sessionId) // captchaKey)
    setLoading(false)
    console.log(typeof generateRequest)
    console.log(JSON.parse(generateRequest).message)
    setCodeSnippet(removeQuotesFromString(JSON.parse(generateRequest).message));
  };


  const recaptchaCallback = (token) => {
    setCaptchaKey(token)
  }


  return (
    <Box>
      {!codeSnippet && (
        <span>
          <div>
            <Heading style={{ display:"inline"}} mb="5">Start writing code with AI</Heading> 
            
            <br />

            <BuildCreditsText></BuildCreditsText>

            
          </div>

          <br/>

          <b><Text mt="4" mb="4">{`${promptForm !== "" ? "Prompt: \n" + promptForm + " " + requirements : ""}`}</Text> {}</b>

          <PromptingForm handlePromptFormChange={handlePromptFormChange}></PromptingForm>
          
          <InspirationModal></InspirationModal>

          <FormControl>
            <FormLabel>Custom requirements</FormLabel>
            <Textarea
              value={requirements}
              onChange={handleChange}
              placeholder="Enter the custom requirements of your Ethereum application. You can mix and match functions together."
            />
          </FormControl>

          <Button
            colorScheme="blue"
            onClick={handleConfirmRequirements}
            mt="20px"
            mb="10px"
            width="100%"
            loadingText='Submitting'
            isDisabled={confimred}
          >
            Confirm requirements
          </Button>

          <div style={{"opacity": !confimred ? "0.05" : accepted ? "0.2" : "1", "backgroundColor": "rgba(0,0,0,0.1)", "padding":"4px 10px 10px 10px"}}>
            <Flex borderRadius={"10px"}  mt={isLargerThanMobile ? "6px" : "28px"} mb="-6px">
              <TermsAndConditions text="See Terms and Conditions" color="blue"></TermsAndConditions>
            </Flex>
            <Flex mt="10px" mb="-6px">
              
              <p> I have read and agree to the Terms And Conditions</p>

              <input
                  style={{marginRight:"5px", transform:"scale(1.4)", cursor:"pointer", margin: "0 0 0 10px"}}
                  type="checkbox"
                  checked={accepted}
                  onChange={handleTermsCheckChange}
              />
            </Flex>
          </div>

          {/* <RecaptchaV3 siteKey="6Ld-0hcnAAAAAFQc64LqIc6wIQsHn4Ph07ZquwK5" action="" verifyCallback={recaptchaCallback}></RecaptchaV3> */}

          <Button
            colorScheme="blue"
            onClick={generateCodeSnippet}
            mt="10px"
            mb="10px"
            width="100%"
            isLoading={loading}
            loadingText="Starting build..."
            style={{"opacity": !accepted ? 0.05 : 1}}
            isDisabled={!accepted}
          >
            Write contract
          </Button>

          <span style={{float:"right", display:"inline", textDecoration:"underline"}}>
              <a href="../../create/ai/results/e9c701e2-3381-4c32-978a-07856dd63257">See a demo</a> 
            </span>
        </span>
      )}
      {codeSnippet && (
        <Box mt={4}>
          <PollingForCode requestId={codeSnippet} requirements={requirements} contractId={codeSnippet ? codeSnippet: ""}></PollingForCode>
        </Box>
      )}
    </Box>
  );
}

export default CodeBot;
