import React, { useEffect } from 'react';
import { Web3Button, Web3Modal, Web3NetworkSwitch } from "@web3modal/react"
import { WagmiConfig } from 'wagmi'
import {
  RouterProvider,
} from "react-router-dom";
import { router } from './router'
import { AppContainer } from './components/AppContainer/AppContainer.js';


const { wagmiClient, ethereumClient, projectId } = require("./libs/wagmi")



const SmartPress = () => {

  useEffect(() => {
    if (!process.env.REACT_APP_API_URL) {
      console.error("API URL is missing from .env")
    }
  }, [])

  return (
      <WagmiConfig client={wagmiClient}>
        <AppContainer>
          <RouterProvider router={router}> </RouterProvider>
        </AppContainer>
      </WagmiConfig>
  );
};

export default SmartPress;

