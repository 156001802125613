import { Radio, RadioGroup, VStack, Heading, Input, FormControl, FormLabel } from "@chakra-ui/react";
import { useState } from "react";

const ChakraRadioButton = ({handlePromptFormChange}) => {
  const [selectedValue, setSelectedValue] = useState("");

  const [name, setName] = useState('');
  const [symbol, setSymbol] = useState('');
  const [supply, setSupply] = useState('');

  const handleRadioChange = (value) => {
    setSelectedValue(value);
    handlePromptFormChange(value);
  };

  const promptChangeHelper = (selectedValue_, name_, symbol_, supply_) => {
    handlePromptFormChange(`
        ${selectedValue_} ${name_ ? "With a name of: " + name_+ ". " : ""} ${symbol_ ? "With a symbol of: " + symbol_+ ". " : ""} ${supply_ ? "With a max supply of: " + supply_ + ". " : ""}`);
  }

  const handleNameChange = (value) => {
    setName(`${value.target.value}`);
    promptChangeHelper(selectedValue, value.target.value, symbol, supply)
  };
  
  const handleSymbolChange = (value) => {
    setSymbol(`${value.target.value}`);
    promptChangeHelper(selectedValue, name, value.target.value, supply)
  };

  const handleSupplyChange = (value) => {
    setSupply(`${value.target.value}`);
    promptChangeHelper(selectedValue, name, symbol, value.target.value)
  };

  return (
    <RadioGroup mb="20px" colorScheme="teal" value={selectedValue} onChange={handleRadioChange}>
      <VStack align="start" spacing={2}>
        <Heading mt="-6" mb="1" size="small">Get creative or start with an app template</Heading>
        <Radio value="">New idea</Radio>
        <Radio value="Is a ERC-20 standard token. ">Token (ERC-20)</Radio>
        <Radio value="Is a ERC-721 standard nft. ">NFT (ERC-721)</Radio>
        {
            selectedValue === "Is a ERC-20 standard token. " || selectedValue === "Is a ERC-721 standard nft. " ?
                <>
                    <FormControl>
                        <FormLabel>Name</FormLabel>
                        <Input
                            value={name}
                            onChange={handleNameChange}
                            placeholder="Enter a name"
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Symbol</FormLabel>
                        <Input
                            value={symbol}
                            onChange={handleSymbolChange}
                            placeholder="Enter a symbol"
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Supply</FormLabel>
                        <Input
                            value={supply}
                            onChange={handleSupplyChange}
                            placeholder="Enter a supply (not required)"
                        />
                    </FormControl>
                </> : <></>
        }
        
      </VStack>
    </RadioGroup>
  );
};

export default ChakraRadioButton;
