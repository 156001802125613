import React from 'react';

const TwitterLink = ({ username, text }) => {
  const twitterUrl = `https://twitter.com/smartpress_ai`;

  return (
    <a
      href={twitterUrl}
      target="_blank"
      rel="noopener noreferrer"
      style={{ display: 'inline-flex', alignItems: 'center', textDecoration: 'none' }}
    >
      <svg
        viewBox="0 0 24 24"
        fill="currentColor"
        height="20"
        width="20"
        style={{ marginRight: '5px' }}
      >
        <path d="M23.954 4.569c-.885.388-1.83.652-2.828.774 1.016-.611 1.798-1.576 2.165-2.732-.951.557-2.005.962-3.125 1.186-.895-.957-2.173-1.555-3.582-1.555-2.71 0-4.902 2.193-4.902 4.892 0 .384.043.757.127 1.115-4.075-.204-7.683-2.156-10.09-5.123-.42.717-.66 1.54-.66 2.413 0 1.676.854 3.155 2.152 4.021-.794-.025-1.542-.243-2.197-.606v.06c0 2.34 1.614 4.287 3.756 4.729-.392.106-.805.161-1.231.161-.3 0-.586-.03-.873-.086.588 1.813 2.289 3.13 4.312 3.163-1.583 1.237-3.574 1.974-5.732 1.974-.375 0-.744-.022-1.113-.065 2.044 1.302 4.463 2.059 7.065 2.059 8.455 0 13.07-7.004 13.07-13.068 0-.199-.005-.397-.014-.595.897-.647 1.68-1.462 2.303-2.388l-.047-.02z" />
      </svg>
    </a>
  );
};

export default TwitterLink;
