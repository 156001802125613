import React, { useState } from 'react';
import { Button, Center } from '@chakra-ui/react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import CopyButton from '../CopyToClipboard/CopyToClipboard'

const ContentReveal = ({ code, rawCode }) => {
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const snippetStyle = {
    maxHeight: showMore ? 'none' : '140px',
    overflow: 'hidden',
  };

  return (
    <div>
      <div style={snippetStyle}>{code}</div>
      <Center>
        {!showMore && <>
            <Button size="sm" style={{top:"-75px"}} onClick={toggleShowMore}>
                Show More
            </Button>
            <CopyButton text={rawCode}  moveUp={true}></CopyButton>
        </>}
        {showMore && <>
            <Button size="sm" m="2" onClick={toggleShowMore}>
                Show Less
            </Button>
            <CopyButton text={rawCode}></CopyButton>
        </>}
      </Center>
    </div>
  );
};

export default ContentReveal;