import { useState } from 'react';
import { ModalComponent } from '../Modal/Modal';
import { Table } from '../Table/Table'
import { Button, Heading, Text, useMediaQuery } from '@chakra-ui/react';

export const FaucetList = () => {

    const [isLargerThanMobile] = useMediaQuery('(min-width: 480px)');

    const [open, setOpen] = useState(false)

    const faucets = {
        polygon: "https://faucet.polygon.technology/",
        sepolia: "https://sepolia-faucet.pk910.de/",
        eosEvm: "https://faucet.testnet.evm.eosnation.io/"
    }

    return (
        <>
            
            <Button mt={isLargerThanMobile ? "2": "4"} size="sm" onClick={() => {
                setOpen(true)
            }}>Need gas?</Button>
            <ModalComponent open={open}>
                <Heading>Faucets</Heading>
                <Text mt="4" mb="4">A faucet is a location where you can get free testnet coins to test deploy contracts. Use these faucets to get gas to deploy your app on a testnet</Text>
                <Table data={faucets}></Table>
                <Button mt="4" onClick={() => {
                    setOpen(false)
                }}>Done</Button>
            </ModalComponent>
        </>
    );
  };
