import React from 'react';
import PillBot from "../components/ChatBot/PillBot";
import { useParams } from 'react-router-dom';

function Integrate() {
  const { contractId } = useParams();

  return (
    <PillBot/>
  );
}
export default Integrate;
