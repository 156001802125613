import React from 'react';
import { Table as T, Tbody, Tr, Td } from '@chakra-ui/react';

import { useMediaQuery } from '@chakra-ui/react';


export const Table = ({ data }) => {
  const [isLargerThanMobile] = useMediaQuery('(min-width: 480px)');
    const entries = Object.entries(data);
    return (
      <T variant="simple">
        <Tbody>
          { isLargerThanMobile 
              ? 
            entries.map(([key, value]) => (
              <Tr key={key + "-4455"}>
                <Td key={key + "-9985"} style={{fontSize:"12px"}} fontWeight="bold" backgroundColor="gray.50">
                  {key}
                </Td>
                <Td style={{fontSize:"12px"}} key={key + "-1111"}>{value}</Td>
              </Tr>
            )) 
              : 
            entries.map(([key, value]) => (
              <>
                <Tr key={key + "-8677"}>
                  <Td key={key + "-4432"} style={{fontSize:"14px"}} fontWeight="bold" backgroundColor="gray.50">
                    {key}
                  </Td>
                </Tr>
                <Tr key={key + "-9998"}>
                  <Td  style={{fontSize:"7px", background:"white"}} key={key + "-4488"}>{value}</Td>
                </Tr>
              </>
            )) 
          }
        </Tbody>
      </T>
    );
  };