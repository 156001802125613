import React, { useEffect, useState } from 'react';
import {abiCoder} from '../../libs/ethers'
import { Box, Button, FormControl, FormLabel, Input } from '@chakra-ui/react';
import { BigNumber } from 'ethers';
import { isNumber } from '../../libs/string'


export function ConstructorForm({ abi, handleArgs }) {
// Find the constructor details in the ABI
  const findConstructor = () => {
    console.log(abi.length)
    for (let i = 0; i < abi.length; i++) {
      const item = abi[i];
      if (item.type === 'constructor' && item.inputs) {
        return item;
      }
    }
    return {inputs: []};
  };

  const [constructorTypes, setConstructorTypes] = useState(findConstructor()?.inputs.map(obj => obj.type));
  const [constructorArgs, setConstructorArgs] = useState(getDefaultValues(constructorTypes));
  
  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Logic to handle the submitted constructor arguments
    console.log(constructorArgs);
  };

  // Handle input change
  const handleInputChange = (e, index) => {
    const args = [...constructorArgs];

    let input;
    if (isNumber(e.target.value)) {
        input = BigNumber.from(e.target.value)
    } else {
        input = e.target.value
    }
    args[index] = e.target.value;
    setConstructorArgs(args);
    handleArgs(abiCoder.encode(constructorTypes, constructorArgs).slice(2))

    console.log(constructorArgs)
  };

  // Render the form inputs for constructor arguments
  const renderInputs = () => {
    const constructor = findConstructor();
    
    console.log(constructor)
    if (constructor && constructor.inputs) {
      return constructor.inputs.map((input, index) => (
        <Box key={index} mb={4}>
        <FormControl>
          <FormLabel>{input.name}:</FormLabel>
          <Input
            type="text"
            value={constructorArgs[index] || ''}
            placeholder={input.type}
            onChange={(e) => handleInputChange(e, index)}
          />
        </FormControl>
      </Box>
      ));
    }
    return null;
  };

  function getDefaultValueByType(type) {
    console.log(type)
    if (type == "address") {
      return "0x0000000000000000000000000000000000000000"; 
    } else if (type == "bool") {
      return false; // Default boolean value
    } else if (type == "uint" || type == "uint8" || type == "int16" || type == "uint256") {
      return BigNumber.from(0); // Default uint value
    } else if (type == "int") {
      return 0; // Default int value
    } else if (type == "bytes") {
      return "0x"; // Default bytes value
    } else if (type == "string") {
      return ""; // Default string value
    } else {
      return null; // Unsupported type
    }
  }
  
  function getDefaultValues(types) {
    const defaultValues = [];
    for (let i = 0; i < types.length; i++) {
      const type = types[i];
      const defaultValue = getDefaultValueByType(type);
      defaultValues.push(defaultValue);
    }
    return defaultValues;
  }

  useEffect(() => {
    try {
      handleArgs(abiCoder.encode(constructorTypes, constructorArgs).slice(2))  
    } catch (error) {
      console.log()
    }
  }, [])

  return (
    <form onSubmit={handleSubmit}>
        { constructorTypes.length > 0 ? 
            <span>
                <Box mb="4">Your code has a constructor. Please fill in these values to configure your code.</Box>
                {renderInputs()}
                {/* <Button mt="5" mb="5" type="submit" onClick={() => handleArgs(abiCoder.encode(constructorTypes, constructorArgs).slice(2))}>Confirm Constructor arguments</Button> */}
            </span>
        : "" }
    </form>
  );
}