import { IconButton, Tooltip, HStack } from '@chakra-ui/react';
import { FaTwitter, FaDiscord, FaEnvelope } from 'react-icons/fa';

export const ShareButton = ({ content }) => {
  const handleTwitterShare = () => {
    const url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(content)}`;
    window.open(url, '_blank');
  };

  const handleDiscordShare = () => {
    // Handle Discord sharing logic here
    // Example: window.open('https://discord.com/...');
  };

  const handleEmailShare = () => {
    const subject = 'EthCreator AI - Ethereum app source code';
    const body = `Hi, here is a link to the source code: ${content}. Warning, be careful deploying this code to anything other than a test environment unless you know exactly what it does. Thanks`;
    const url = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = url;
  };

  return (
    <HStack spacing="2">
      <Tooltip label="Share on Twitter">
        <IconButton
          aria-label="Twitter"
          icon={<FaTwitter />}
          onClick={handleTwitterShare}
        />
      </Tooltip>
      <Tooltip label="Share via Email">
        <IconButton
          aria-label="Email"
          icon={<FaEnvelope />}
          onClick={handleEmailShare}
        />
      </Tooltip>
    </HStack>
  );
};

