import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
} from '@chakra-ui/react';
import DisplayJson from '../DisplayJson/DisplayJson';

const ContractDescriptions = () => {
    const contractDescriptions = {
        "Staking Contract": "A staking app allows users to lock up their tokens in return for rewards. It is commonly used to secure and validate a blockchain network by incentivizing token holders to participate in the network's consensus mechanism.",
        "Escrow Contract": "An escrow app acts as a trusted intermediary that holds funds or assets on behalf of two or more parties in a transaction. It ensures that both parties fulfill their obligations before releasing the funds or assets.",
        "Time-Lock Contract": "A time-lock app restricts access to certain functions or funds until a specified time or block height is reached. It is useful for executing actions at a predetermined future date or for implementing vesting schedules.",
        "Gnosis Safe Contract (Multisig)": "A MultiSig app is a multi-signature app that requires multiple parties to sign off on a transaction before it can be executed. It enhances security by reducing the risk of single points of failure.",
        "Oracle consumer": "An oracle consumer app is a app that reads oracles info (likle chainlink) and does some function with that information. Use this in conjuntion with other functions.",
        "DAO (Decentralized Autonomous Organization) Contract": "A DAO app represents a decentralized organization governed by solidity contracts. It allows members to participate in decision-making processes and voting on proposals related to the organization.",
        "Token Contract": "A token app is used to create fungible or non-fungible tokens on a blockchain. It defines the rules for token issuance, transfer, and other functionalities.",
        "ERC-20 Contract": "ERC-20 is a standard interface for fungible tokens on the Ethereum blockchain. It defines a set of rules and functions that ERC-20 compliant tokens must implement to ensure interoperability.",
        "ERC-721 Contract": "ERC-721 is a standard interface for non-fungible tokens on the Ethereum blockchain. It enables the creation of unique tokens, often used for representing digital assets like collectibles and game items.",
        "ERC-1155 Contract": "ERC-1155 is a standard interface that supports both fungible and non-fungible tokens on the Ethereum blockchain. It allows the efficient management of multiple token types in a single contract.",
        "Non-Fungible Token (NFT) Contract": "A Non-Fungible Token (NFT) app is used to create unique digital assets that cannot be exchanged on a one-to-one basis due to their distinct characteristics and ownership history.",
        "DeFi (Decentralized Finance) Contract": "A DeFi app is part of the decentralized finance ecosystem and typically involves various financial services, including lending, borrowing, trading, and yield farming, all governed by solidity contracts.",
        "Flash Loan Contract": "A flash loan app enables users to borrow funds for a single transaction without requiring collateral. The loan must be repaid within the same transaction, making it suitable for arbitrage opportunities.",
        "Yield Farming Contract": "A yield farming app incentivizes liquidity providers to deposit their funds in a liquidity pool by rewarding them with additional tokens or fees.",
        "Governance Token Contract": "A governance token app provides holders with voting rights to influence decisions related to a protocol or platform.",
        "Wrapped Token Contract": "A wrapped token app represents an asset from one blockchain (e.g., BTC) as an ERC-20 token on another blockchain (e.g., Ethereum). It enables cross-chain interoperability.",
        "Synthetic Asset Contract": "A synthetic asset app represents an artificial token that tracks the value of an underlying asset, allowing users to gain exposure to assets without holding them directly.",
        "Prediction Market Contract": "A prediction market app enables users to create and trade tokens representing the likelihood of specific events occurring. It can be used for crowd-sourced forecasting and hedging."
      };

  return (
    <div>
        <DisplayJson data={contractDescriptions}></DisplayJson>
    </div>
  );
};

const InspirationModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Button mb="2" mt="-2" ml="-2" size="sm" style={{transform:"scale(0.8)"}} onClick={handleOpenModal}>+ more options</Button>
      <Modal isOpen={isOpen} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent maxW="85%" w="85vw">
          <ModalHeader style={{fontSize:"28px"}}>Solidity Contract Glossary</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ContractDescriptions />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default InspirationModal;